import {BoatSubscriptionModeType} from './boat-subscription-package.model';

import {BoatPayload} from '../boat';

export interface BoatSubscriptionPromoCodeForm {
  planId: number;
  planMode: BoatSubscriptionModeType;
  promoCode: string;
}

export interface BoatSubscriptionPromoCodePayload
  extends BoatSubscriptionPromoCodeForm, BoatPayload {
}

export class BoatSubscriptionWithPromoCode {
  constructor(
    public readonly promoCode: BoatSubscriptionPromoCode,
    public readonly discountFinalCost: BoatSubscriptionFinalCostDto,
  ) {}
}

export class BoatSubscriptionPromoCode {
  constructor(
    public readonly discountType: BoatSubscriptionPromoCodeType,
    public readonly discountValue: number,
  ) {}
}

export class BoatSubscriptionFinalCostDto {
  constructor(
    public readonly month: number,
    public readonly year: number,
  ) {}
}

export enum BoatSubscriptionPromoCodeType {
  Percent = 1,
  Amount,
}
