import {BoatPayload} from '../boat';
import {ConditionalStatus} from '../general';

export enum BoatSubscriptionModeType {
  Month = 1,
  Year,
}

export enum BoatSubscriptionTrialType {
  TrialOn = 1,
  TrialOff,
  TrialUnlimited
}

export class BoatSubscriptionPackageDto {
  constructor(
    public readonly id: number,
    public readonly plan: BoatSubscriptionPlanDetailsDto,
    public readonly monthly: BoatSubscriptionModeDto,
    public readonly annually: BoatSubscriptionModeDto,
    public readonly trial: boolean,
  ) { }
}

export class BoatSubscriptionPlanDetailsDto {
  constructor(
    public readonly minLength: number,
    public readonly maxLength: number,
  ) { }
}

export class BoatSubscriptionModeDto {
  constructor(
    public readonly basePrice: number,
    public readonly maxCrew: number,
    public readonly maxManager: number,
    public readonly planMode: BoatSubscriptionModeType,
    public readonly additionalUsers: BoatSubscriptionAdditionalUsersDto | null,
    public readonly finalPrice: number,
    public readonly disableChoose: boolean,
    public readonly monthlyPlanPrice?: number,
    public readonly perMonth?: number,
    public readonly saving?: number,
  ) { }
}

export class BoatSubscriptionAdditionalUsersDto {
  constructor(
    public readonly quantity: number,
    public readonly price: number,
  ) { }
}

export interface BoatSubscriptionRenewalPayload extends BoatPayload {
  renewal: ConditionalStatus;
}

export interface BoatChangeSubscriptionPayload extends BoatPayload {
  promoCode?: string;
}
